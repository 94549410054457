@charset "UTF-8";

/*
#overview
base
*/
/*
#colors
@$color1 #0a6f3a
@$color2 #cee2d8
@$textColor #000
*/
$color1: #0a6f3a;
$color2: #cee2d8;
$textColor: #000;


// font
$baseFont: 15;
$mincho: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", serif;
$Gothic: "Noto Sans JP","ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
$NotoSerif: 'Noto Serif JP';
$wFont1: 'Old Standard TT';

$baseWidth: 1000px;
$maxWidth: 2000px;

$bpPc: 1366px;
$bpPcs: 1000px;
$bpTb: 768px;
$bpSp: 640px;
$bpSps: 425px;


// z-index
// ↑レイヤー上
// ↓レイヤー下
// eg. z-index: z(child);
$z-index:(
  child,
  nav,
  pulldown,
  header
);
// リストを逆転させる
@function list-reverse($map) {
  $result: ();
  @for $i from length($map)*-1 through -1 {
    $result: append($result, nth($map, abs($i)), comma);
  }
  @return $result;
}
$z-index: list-reverse($z-index);

@function z($name) {
  @return index($z-index, $name);
}
