@charset "UTF-8";

/*
#styleguide
.m-btn1

aタグ、buttonタグ、input[type=submit]、input[type=button]に対応
```
<a href="#" class="m-btn1">詳しく見る</a>
```
*/
.m-btn1 {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 245px;
  padding: 10px 25px;
  font-family: $mincho;
  @include f-size(13);
  background: transparent;
  // outline: none !important;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
  &.height2 {
    padding: 5px 25px;
  }
}

.btn-design {
  position: relative;
  border: 1px solid $textColor;
  z-index: 1;
  overflow: visible;
  &::before {
    position: absolute;
    content: "";
    z-index: -1;
    width: 48px;
    top: 50%;
    left: 220px;
    height: 1px;
    background: $textColor;
    transition: all 0.3s ease;
  }
  &:hover {
    &::before {
      width: 70px;
    }
  }

  &::after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    right: 0;
    z-index: -1;
    background: $textColor;
    transition: all 0.3s ease;
  }
  &:hover {
    color: #fff;
    text-decoration: none;
    &::after {
      left: 0;
      width: 100%;
    }
  }
}


/*
#styleguide
.m-btn2

aタグ、buttonタグ、input[type=submit]、input[type=button]に対応
```
<a href="#" class="m-btn2">詳しく見る</a>
```
*/
.m-btn2 {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 245px;
  padding: 10px 25px;
  font-family: $mincho;
  @include f-size(13);
  background: transparent;
  // outline: none !important;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
}

.btn-design2 {
  position: relative;
  border: 1px solid $textColor;
  z-index: 1;
  overflow: visible;
  &::before {
    position: absolute;
    content: "";
    z-index: -1;
    width: 48px;
    top: 50%;
    left: -25px;
    height: 1px;
    background: $textColor;
    transition: all 0.3s ease;
  }
  &:hover {
    &::before {
      width: 48px;
    }
  }

  &::after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    right: 0;
    z-index: -1;
    background: $textColor;
    transition: all 0.3s ease;
  }
  &:hover {
    color: #fff;
    text-decoration: none;
    &::after {
      left: 0;
      width: 100%;
    }
  }
}