@charset "UTF-8";
// @import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP&subset=japanese);
// @import url(https://fonts.googleapis.com/css?family=Noto+Serif+JP&subset=japanese);
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP);
@import url(https://fonts.googleapis.com/css?family=Noto+Serif+JP);
@import url(https://use.fontawesome.com/releases/v5.7.2/css/all.css);

/*********************************************
DEFAULT
*********************************************/
*,
*::before,
*::after {
  box-sizing: border-box;
}
body * {
  -webkit-text-size-adjust: 100%;
}
.serif { /* 本文用 */
  font-family: "Times New Roman", "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "メイリオ", Meiryo, serif;
}
.serifH { /* 見出し用 */
  font-family: "Times New Roman", "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "メイリオ", Meiryo, serif;
}
a img {
  transition-property: opacity;
  transition-duration: .2s;
  backface-visibility: hidden;
}
a:hover {
  text-decoration: underline;
}
a:hover img {
  opacity: .8;
}
iframe[name=google_conversion_frame] {
  position: absolute;
  top: 0;
}
/* iOSでのデフォルトスタイルをリセット */
input[type="submit"],
input[type="button"] {
  border-radius: 0;
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
}
input[type="submit"]::-webkit-search-decoration,
input[type="button"]::-webkit-search-decoration {
  display: none;
}
input[type="submit"]::focus,
input[type="button"]::focus {
  outline-offset: -2px;
}


/*********************************************
Customize
*********************************************/
html {
  font-family: $Gothic;
  font-size: 62.5%;
}
body {
  color: $textColor;
  @include f-size($baseFont);
  line-height: 1.8;
}
a {
  color: $textColor;
}
img {
  max-width: 100%;
  height: auto;
}
