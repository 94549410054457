@charset "UTF-8";

/*
#styleguide
.m-title1

```
<div class="m-title1">
  <p class="m-title1-ja">特徴</p>
  <p class="m-title1-en">Feature</p>
</div>
```
*/
.m-title1 {
  position: relative;
  font-family: $mincho;
  @include f-size(32);
  text-align: center;
  letter-spacing: 0.05em;
  line-height: 1.5;
  @include bpPc {
    @include f-size(30);
  }
  @include bpPcs {
    @include f-size(26);
  }
  @include bpSp {
    @include f-size(22);
  }
  span {
    display: block;
    padding-top: 10px;
    font-family: $wFont1;
    @include f-size(14);
    letter-spacing: 0;
    @include bpPcs {
      padding-top: 5px;
      @include f-size(13);
    }
  }
}

/*
#styleguide
.m-title2

```
<h3 class="m-title2">日本の芸術文化のために<br>
私たちにできること。</h3>
```
*/
.m-title2 {
  position: relative;
  font-family: $mincho;
  @include f-size(32);
  text-align: center;
  letter-spacing: 0.05em;
  line-height: 1.5;
  @include bpPc {
    @include f-size(30);
  }
  @include bpPcs {
    @include f-size(26);
  }
  @include bpSp {
    @include f-size(22);
  }
  &::after {
    content: '';
    display: block;
    border-top: 1px solid $color1;
    width: 100px;
    margin: 15px auto 0;
    @include bpSp {
      width: 55px;
      margin-top: 15px;
    }
  }
}

/*
#styleguide
.m-title3

```
<h3 class="m-title3">日本の芸術文化のために<br>
私たちにできること。</h3>
```
*/
.m-title3 {
  position: relative;
  padding-left: 35px;
  color: $color1;
  @include f-size(25);
  @include bpSp {
    @include f-size(17);
  }
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 25px;
    height: 1px;
    background-color: $color1;
  }
  .small-text {
    display: inline-block;
    padding-left: 15px;
    color: $color1;
    vertical-align: middle;
    line-height: 2.5em;
    @include f-size(13);
    @include bpSp {
      padding-left: 8px;
      @include f-size(11);
    }
  }
}
