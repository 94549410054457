@charset "UTF-8";

/*
#overview
layout
*/

$lBp: 910px; // レイアウト用のブレークポイント

// 基本設定
// 書体設定
body {
  font-family: $mincho;
}

// 文字色
.white {
  color: #fff;
}

// 空白
.l-space {
  display: inline-block;
  width: 75px;
  padding-left: 25px;
  padding-right: 20px;
  @include bpSp {
    display: none;
  }
}

// ヘッダー
.l-header {
  background-color: #fff;
  height: 75px;
  @include bpPcs {
    position: relative;
    height: 60px;
    background-color: none;
  }

  .l-header-important {
    background-color: rgba(199, 47, 44, 0.7);
    position: relative;
    z-index: 1;
    padding-left: 220px;
    text-align: center;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-right: 20px;

    @include breakpoint(1550px) {
      text-align: left;
    }

    @include bpPcs {
      padding-left: 20px;
      padding-right: 20px;
      top: 60px;
    }

    @include bpSp {
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 6px;
      padding-bottom: 6px;
    }

    &-text {
      color: #fff;

      @include bpPcs {
        @include f-size(14);
      }

      @include bpSp {
        @include f-size(12);
      }

      &-en {
        text-align: left;
      }
    }
  }

  .l-header-info {
    display: flex;
    justify-content: flex-end;
    @include bpPcs {
      display: none;
    }
    .btn-ja,
    .btn-en {
      display: block;
      width: 50px;
      height: 50px;
      padding: 12px;
      text-align: center;
      &:hover {
        background-color: #fff;
        text-decoration: none;
      }
    }
    .btn-ja {
      background-color: #edf0ec;
    }
    .btn-en {
      background-color: #fff;
    }
  }
  .l-header-nav-btn {
    display: none;
    @include bpPcs {
      display: block;
      position: fixed;
      top: 0;
      right: 0;
      z-index: z(child);
      width: 60px;
      height: 60px;
      z-index: 9999;
      button {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 0;
      }
      span {
        position: absolute;
        left: 15px;
        transition: all .3s ease;
        background-color: $textColor;
        width: 30px;
        height: 1px;
        &:nth-of-type(1) {
          top: 25px;
        }
        &:nth-of-type(2) {
          top: 36px;
        }
      }
      &.is-open {
        position: fixed;
        button {
          &::after {
            color: $textColor;
          }
        }
        span {
          &:nth-of-type(1) {
            top: 28px;
            transform: rotate(-45deg);
            background-color: $textColor;
          }
          &:nth-of-type(2) {
            top: 28px;
            transform: rotate(-135deg);
            background-color: $textColor;
          }
        }
      }
    }
  }
}

.l-nav-body {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 75px;
  background-color: #fff;
  z-index: 2000;
  transition: .5s;

  @include bpPcs {
    // overflow-y: scroll;
    height: 60px;
  }

  &:after {
    position: absolute;
    bottom: 0;
    left: 0;
    border-bottom: 1px solid #d7d7d6;
    width: 100%;
    height: 1px;
  }
  @include bpPcs {
    // position: static;
    width: 100%;
    padding: 0;
    border-right: none;
    height: 60px;
    vertical-align: text-bottom;
  }
  .l-header-logo {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin-right: 35px;
    img {
      vertical-align: text-bottom;
    }
    @include bpPcs {
      position: fixed;
      // top: 50%;
      top: 0;
      left: 0;
      padding-bottom: 0;
      // transform: translateY(-50%);
      width: 60%;
      height: 60px;
      max-width: 300px;
      margin-right: 0;
      margin-left: 15px;
      img {
        width: 100%;
      }
    }
  }
  .l-nav {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;  
    ul {
      display: flex;
    }
    li {
      position: relative;
      text-align: center;
      width: 100%;
      &.btn-ja-pc,
      &.btn-en-pc {
        @include bpPcs {
          display: none;
        }
      }
      &.l-nav-logo {
        display: none;
        @include bpPcs {
          display: block;
          border-bottom: none;
          padding-bottom: 20px;
        }
        img {
          @include bpPcs {
            width: 12.5%;
            margin: 0 auto;
          }
          @include bpSp {
            width: 15%;
          }
        }
      }
      &.l-btn-sp {
        display: none;
        @include bpPcs {
          display: block;
        }
      }
      &.menu-border {
        border-bottom: 1px solid #cfcfcf;
        @include bpPcs {
          border-bottom: none;
        }
      }
      &:last-child {
        @include bpPcs {
          border-bottom: none;
        }
      }
      a {
        display: block;
        padding: 26px;
        white-space: pre;
        @include f-size(13);
        font-weight: 400;
        transition: .3s;
        &:hover {
          background-color: $color2;
          text-decoration: none;
          @include bpPcs {
            opacity: 0.9;
            background-color: none;
          }
        }
      }
      .l-sub-menu {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        position: absolute;
        top: 75px;
        left: 0;
        transition: .3s;
        width: 100%;
        margin: 0 auto;
        text-align: center;
        opacity: 0;
        visibility: hidden;
        z-index: 2001;
        a {
          padding: 15px 26px 15px 10px;
          text-align: left;
        }
      }
      &:hover {
        .l-sub-menu {
          background-color: $color1;
          opacity: 1;
          visibility: visible;
          li {
            a {
              color: #fff;
              &:hover {
                opacity: 0.75;
                background-color: $color1;
              }
            }
          }
        }
      }
    }

    li.sp-only {
      display: none;
    }
    @include bpPcs {
      // display: none;
      opacity: 0;
      transition: all .55s ease;
      overflow-y: scroll;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 0;
      z-index: z(nav);
      ul {
        display: none;
        padding: 20vw 0 20px;
      }
      li {
        opacity: 0;
        animation: nav-animation 1.5s cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards 1;
        a {
          border-bottom: 0;
          padding: 15px 0;
          color: $textColor;
          @include f-size(14);
        }

        .l-header-info-sp {
          display: none;
          @include bpPcs {
            display: flex;
            justify-content: center;
            padding-top: 15px;
            padding-bottom: 20vw;
            .btn-ja,
            .btn-en {
              width: 50px;
              height: 50px;
              border: none;
              a {
                display: block;
                width: 50px;
                height: 50px;
                padding: 12px;
                text-align: center;
                &:hover {
                  opacity: 0.9;
                  text-decoration: none;
                }
              }
            }
            .btn-ja {
              background-color: #edf0ec;
            }
            .btn-en {
              background-color: #fff;
            }
          }
          @include bpTb {
            padding-bottom: 40vw;
          }
        }
      }
      li.sp-only {
        display: block;
      }
      &.is-open {
        opacity: 1;
        background-color: rgba(#f4f6f5, .90);
        height: 100vh;
        ul {
          display: block;
          &.l-sub-menu {
            display: none;
          }
        }
        li {
          &:nth-of-type(1) {
            animation-delay: .05s;
          }
          &:nth-of-type(2) {
            animation-delay: .1s;
          }
          &:nth-of-type(3) {
            animation-delay: .15s;
          }
          &:nth-of-type(4) {
            animation-delay: .2s;
          }
          &:nth-of-type(5) {
            animation-delay: .25s;
          }
          &:nth-of-type(6) {
            animation-delay: .3s;
          }
          &:nth-of-type(7) {
            animation-delay: .35s;
          }
          &:nth-of-type(8) {
            animation-delay: .4s;
          }
          .l-header-info-sp {
            display: none;
            @include bpPcs {
              display: flex;
            }
          }
        }
      }
    }
  }
}

.l-nav-body::-webkit-scrollbar{
  display: none;
}
.l-nav-body {
  -ms-overflow-style:none;
}

@keyframes nav-animation {
  0% {
    transform: translate3d(0, 50px, 0);
    opacity: 0
  }
  50% {
    opacity: 0
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1
  }
}

// フッター
.l-footer {
  position: relative;
  .l-pagetop {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%,-50%);
    a {
      display: inline-block;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background-color: $color1;
      &:hover {
        text-decoration: none;
      }
      @include bpPcs {
        display: block;
      }
      i {
        display: block;
        padding-top: 6px;
        color: #fff;
        @include f-size(24);
        text-align: center;
      }
    }
  }
  .footer-row1 {
    background-color: $color1;
    padding: 55px 0 60px;
    @include bpPcs {
      padding: 60px 0 0;
    }
    @include bpSp {
      padding: 45px 0 0;
    }
  }
  .footer-nav {
    display: flex;
    justify-content: flex-start;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 25px;
    @include bpPcs {
      flex-wrap: wrap;
      padding-left: 0;
    }
    .footer-nav-item {
      width: 20%;
      color: #fff;
      line-height: 2.1;
      @include bpPcs {
        width: 100%;
        padding-right: 0;
      }
      .modal {
        span {
          display: flex;
          justify-content: space-between;
          position: relative;
          a {
            width: 100%;
          }
          .modalBtn {
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
            width: 30px;
            height: 30px;
            @include bpPcs {
              &::after {
                font-family: "FontAwesome";
                content: "\f107";
                font-weight: 900;
                position: absolute;
                top: 39%;
                left: 50%;
                transform: translate(-50%,-50%);
                width: 30px;
                height: 30px;
                @include f-size(18);
              }
              &.open {
                &::after {
                  font-family: "FontAwesome";
                  content: "\f106";
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%,-50%);
                  width: 30px;
                  height: 30px;
                }
              }
            }
          }
        }
      }
      // &.is-bpPcs-hidden {
      //   @include bpPcs {
      //     display: none;
      //   }
      // }
      &:first-child {
        @include f-size(13);
        line-height: 1.7;
        margin-right: 40px;
        @include bpPcs {
          margin-right: 0;
          padding-bottom: 20px;
          border-bottom: 1px solid #85b79d;
          @include f-size(18);
          text-align: center;
        }
        @include bpSp {
          @include f-size(14);
        }
      }
      &:nth-child(2) {
        width: 12%;
        @include bpPcs {
          width: 100%;
        }
      }
      // &:nth-child(3) {
      //   width: 29%;
      //   @include bpPcs {
      //     width: 100%;
      //   }
      // }
      &:last-child {
        padding-right: 0;
      }
      // &:not(:nth-child(n+3)) {
      //   padding-top: 0;
      // }
      .title1 {
        display: block;
        @include f-size(18);
        @include bpPcs {
          display: inline-block;
          @include f-size(24);
        }
      }
      .title2 {
        display: block;
        padding-top: 8px;
        font-family: $wFont1;
        @include f-size(12);
        @include bpPcs {
          padding-top: 0;
          @include f-size(15);
        }
      }
      a,
      .requirement {
        display: block;
        color: #fff;
        @include f-size(13);
        &:hover {
          opacity: 0.95;
          text-decoration: none;
          -webkit-font-smoothing: antialiased;
        }
        @include bpPcs {
          padding: 20px 3%;
          border-bottom: 1px solid #85b79d;
          @include f-size(16);
        }
        @include bpSp {
          padding: 15px 5%;
          @include f-size(14);
        }
      }
      .footer-nav-more {
        @include bpPcs {
          display: none;
        }
        li {
          @include bpPcs {
            background-color: #fff;
          }
          a {
            padding-left: 0.8em;
            @include f-size(12);
            @include bpPcs {
              padding-left: 2em;
              color: $textColor;
              border-color: #cfcfcf;
              @include f-size(16);
            }
            @include bpSp {
              @include f-size(14);
            }
          }
        }
      }
    }

    .requirement {
      position: relative;
      @include bpPcs {
      &::after {
        font-family: "FontAwesome";
        content: "\f107";
        font-weight: 900;
        position: absolute;
        top: 21%;
        right: 0;
        width: 30px;
        height: 30px;
        @include f-size(18);
      }
      &.open {
        &::after {
          font-family: "FontAwesome";
          content: "\f106";
          position: absolute;
          top: 21%;
          right: 0;
          width: 30px;
          height: 30px;
        }
      }
    }
  }
  }
  .footer-row2 {
    padding-top: 20px;
    padding-bottom: 20px;
    @include bpPcs {
      padding-bottom: 30px;
    }
    .copyright {
      display: block;
      padding-right: 20px;
      font-family: $wFont1;
      @include f-size(11);
      text-align: right;
      line-height: 1;
      @include bpPcs {
        padding-right: 0;
        text-align: center;
        line-height: 1.6;
      }
    }
  }
}

// メイン設定
.l-main {
}

.l-content {
  @include wrapper;
  &.notfound {
    padding: 80px 0;
    .m-title3 {
      margin-bottom: 20px;
    }
  }
}

// メインビジュアル（TOP以外）
.l-mainvisual {
  padding-bottom: 32.25%;
  @include bpSp {
    padding-bottom: 50%;
  }
  &.concept {
    background: url(../img/concept/mainimg.jpg) no-repeat center top;
    background-size: cover;
  }
  &.painting {
    background: url(../img/painting/mainimg.jpg) no-repeat center top;
    background-size: cover;
  }
  &.movie {
    background: url(../img/movie/mainimg.jpg) no-repeat center top;
    background-size: cover;
  }
  &.aid {
    background: url(../img/aid/mainimg.jpg) no-repeat center top;
    background-size: cover;
  }
  &.about {
    background: url(../img/about/mianimg.jpg) no-repeat center top;
    background-size: cover;
  }
  &.culture {
    background: url(../img/culture/mainimg.jpg) no-repeat center top;
    background-size: cover;
  }
}

// ぱんくずリスト
.l-breadCrumbs {
  width: 100%;
  padding: 13px 13px 0 13px;
  @include f-size(12);
  letter-spacing: 0.05em;
  @include bpSp {
    @include f-size(10);
  }
  a {
    @include f-size(12);
  }
  span {
    @include f-size(12);
  }
  span[typeof="ListItem"] {
    @include f-size(12);
    a {
      @include f-size(12);
    }
  }
}

// #en {
//   /*
//   #overview
//   layout
//   */
  
//   $lBp: 910px; // レイアウト用のブレークポイント
  
//   // 基本設定
//   // 書体設定
//   body {
//     font-family: $mincho;
//   }
  
//   // 文字色
//   .white {
//     color: #fff;
//   }
  
//   // 空白
//   .l-space {
//     display: inline-block;
//     width: 75px;
//     padding-left: 25px;
//     padding-right: 20px;
//     @include bpSp {
//       display: none;
//     }
//   }
  
//   // ヘッダー
//   .l-header {
//     background-color: #fff;
//     height: 50px;
//     @include bpPcs {
//       position: relative;
//       height: 60px;
//     }
  
//     .l-header-important {
//       background-color: rgba(199, 47, 44, 0.7);
//       position: relative;
//       z-index: 1;
//       padding-left: 220px;
//       text-align: center;
//       padding-top: 12px;
//       padding-bottom: 12px;
//       padding-right: 20px;
  
//       @include breakpoint(1550px) {
//         text-align: left;
//       }
  
//       @include bpPcs {
//         padding-left: 20px;
//         padding-right: 20px;
//         top: 60px;
//       }
  
//       @include bpSp {
//         padding-left: 10px;
//         padding-right: 10px;
//         padding-top: 6px;
//         padding-bottom: 6px;
//       }
  
//       &-text {
//         color: #fff;
  
//         @include bpPcs {
//           @include f-size(14);
//         }
  
//         @include bpSp {
//           @include f-size(12);
//         }
  
//         &-en {
//           text-align: left;
//         }
//       }
//     }
  
//     .l-header-info {
//       display: flex;
//       justify-content: flex-end;
//       @include bpPcs {
//         display: none;
//       }
//       .btn-ja,
//       .btn-en {
//         display: block;
//         width: 50px;
//         height: 50px;
//         padding: 12px;
//         text-align: center;
//         &:hover {
//           background-color: #fff;
//           text-decoration: none;
//         }
//       }
//       .btn-ja {
//         background-color: #edf0ec;
//       }
//       .btn-en {
//         background-color: #fff;
//       }
//     }
//     .l-header-nav-btn {
//       display: none;
//       @include bpPcs {
//         display: block;
//         position: absolute;
//         top: 0;
//         right: 0;
//         z-index: z(child);
//         width: 60px;
//         height: 60px;
//         button {
//           position: relative;
//           width: 100%;
//           height: 100%;
//           padding: 0;
//         }
//         span {
//           position: absolute;
//           left: 15px;
//           transition: all .3s ease;
//           background-color: $textColor;
//           width: 30px;
//           height: 1px;
//           &:nth-of-type(1) {
//             top: 25px;
//           }
//           &:nth-of-type(2) {
//             top: 36px;
//           }
//         }
//         &.is-open {
//           position: fixed;
//           button {
//             &::after {
//               color: $textColor;
//             }
//           }
//           span {
//             &:nth-of-type(1) {
//               top: 28px;
//               transform: rotate(-45deg);
//               background-color: $textColor;
//             }
//             &:nth-of-type(2) {
//               top: 28px;
//               transform: rotate(-135deg);
//               background-color: $textColor;
//             }
//           }
//         }
//       }
//     }
//   }
  
//   .l-nav-body {
//     position: fixed;
//     top: 0;
//     left: 0;
//     display: block;
//     width: 200px;
//     height: 100%;
//     padding: 40px 0 70px;
//     background-color: #fff;
//     border-right: 1px solid #d7d7d6;
//     z-index: 2000;
//     overflow-y: scroll;
//     transition: .5s;
//     @include bpPcs {
//       position: static;
//       width: 100%;
//       padding: 0;
//       border-right: none;
//       background-color: transparent;
//     }
//     .l-header-logo {
//       display: block;
//       padding-bottom: 50px;
//       text-align: center;
//       height: auto;
//       @include bpPcs {
//         position: absolute;
//         top: 50%;
//         left: 15px;
//         padding-bottom: 0;
//         transform: translateY(-50%);
//         width: 60%;
//         max-width: 300px;
//         img {
//           width: 100%;
//         }
//       }
//     }
//     .l-nav {
//       display: block;
//       ul {
//         display: block;
//       }
//       li {
//         position: relative;
//         border-top: 1px solid #cfcfcf;
//         text-align: center;
//         &.l-nav-logo {
//           display: none;
//           @include bpPcs {
//             display: block;
//             border-top: none;
//             padding-bottom: 20px;
//           }
//           img {
//             @include bpPcs {
//               width: 12.5%;
//               margin: 0 auto;
//             }
//             @include bpSp {
//               width: 15%;
//             }
//           }
//         }
//         &.l-btn-sp {
//           display: none;
//           @include bpPcs {
//             display: block;
//           }
//         }
//         &.menu-border {
//           border-bottom: 1px solid #cfcfcf;
//           @include bpPcs {
//             border-bottom: none;
//           }
//         }
//         &:last-child {
//           border-bottom: 1px solid #cfcfcf;
//           @include bpPcs {
//             border-bottom: none;
//           }
//         }
//         a {
//           display: block;
//           padding: 13px 0;
//           white-space: pre;
//           @include f-size(13);
//           font-weight: 400;
//           transition: .3s;
//           &:hover {
//             background-color: $color2;
//             text-decoration: none;
//             @include bpPcs {
//               opacity: 0.9;
//               background-color: none;
//             }
//           }
//         }
//         .l-sub-menu {
//           position: absolute;
//           top: 72px;
//           left: 0;
//           transition: .3s;
//           width: 100%;
//           margin: 0 auto;
//           text-align: center;
//           opacity: 0;
//           visibility: hidden;
//           z-index: 2001;
//         }
//         &:hover {
//           .l-sub-menu {
//             background-color: $color1;
//             opacity: 1;
//             visibility: visible;
//             li {
//               a {
//                 color: #fff;
//                 &:hover {
//                   background-color: $color1;
//                   opacity: 0.95;
//                 }
//               }
//             }
//           }
//         }
//       }
  
//       li.sp-only {
//         display: none;
//       }
//       @include bpPcs {
//         // display: none;
//         opacity: 0;
//         transition: all .55s ease;
//         overflow-y: scroll;
//         position: fixed;
//         top: 0;
//         left: 0;
//         width: 100%;
//         height: 0;
//         z-index: z(nav);
//         ul {
//           display: none;
//           padding: 20vw 0 20px;
//         }
//         li {
//           opacity: 0;
//           animation: nav-animation 1.5s cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards 1;
//           a {
//             border-bottom: 0;
//             padding: 15px 0;
//             color: $textColor;
//             @include f-size(14);
//           }
  
//           .l-header-info-sp {
//             display: none;
//             @include bpPcs {
//               display: flex;
//               justify-content: center;
//               padding-top: 15px;
//               padding-bottom: 20vw;
//               .btn-ja,
//               .btn-en {
//                 width: 50px;
//                 height: 50px;
//                 border: none;
//                 a {
//                   display: block;
//                   width: 50px;
//                   height: 50px;
//                   padding: 12px;
//                   text-align: center;
//                   &:hover {
//                     opacity: 0.9;
//                     text-decoration: none;
//                   }
//                 }
//               }
//               .btn-ja {
//                 background-color: #edf0ec;
//               }
//               .btn-en {
//                 background-color: #fff;
//               }
//             }
//             @include bpTb {
//               padding-bottom: 40vw;
//             }
//           }
//         }
//         li.sp-only {
//           display: block;
//         }
//         &.is-open {
//           opacity: 1;
//           background-color: rgba(#f4f6f5, .90);
//           height: 100vh;
//           ul {
//             display: block;
//             &.l-sub-menu {
//               display: none;
//             }
//           }
//           li {
//             &:nth-of-type(1) {
//               animation-delay: .05s;
//             }
//             &:nth-of-type(2) {
//               animation-delay: .1s;
//             }
//             &:nth-of-type(3) {
//               animation-delay: .15s;
//             }
//             &:nth-of-type(4) {
//               animation-delay: .2s;
//             }
//             &:nth-of-type(5) {
//               animation-delay: .25s;
//             }
//             &:nth-of-type(6) {
//               animation-delay: .3s;
//             }
//             &:nth-of-type(7) {
//               animation-delay: .35s;
//             }
//             &:nth-of-type(8) {
//               animation-delay: .4s;
//             }
//             .l-header-info-sp {
//               display: none;
//               @include bpPcs {
//                 display: flex;
//               }
//             }
//           }
//         }
//       }
//     }
//   }
  
//   .l-nav-body::-webkit-scrollbar{
//     display: none;
//   }
//   .l-nav-body {
//     -ms-overflow-style:none;
//   }
  
//   @keyframes nav-animation {
//     0% {
//       transform: translate3d(0, 50px, 0);
//       opacity: 0
//     }
//     50% {
//       opacity: 0
//     }
//     100% {
//       transform: translate3d(0, 0, 0);
//       opacity: 1
//     }
//   }
  
//   // フッター
//   .l-footer {
//     position: relative;
//     padding-left: 200px;
//     @include bpPcs {
//       padding-left: 0;
//     }
//     .l-pagetop {
//       position: absolute;
//       top: 0;
//       left: 50%;
//       transform: translate(-50%,-50%);
//       padding-left: 200px;
//       @include bpPcs {
//         padding-left: 0;
//       }
//       a {
//         display: inline-block;
//         width: 60px;
//         height: 60px;
//         border-radius: 50%;
//         background-color: $color1;
//         &:hover {
//           text-decoration: none;
//         }
//         @include bpPcs {
//           display: block;
//         }
//         i {
//           display: block;
//           padding-top: 6px;
//           color: #fff;
//           @include f-size(24);
//           text-align: center;
//         }
//       }
//     }
//     .footer-row1 {
//       background-color: $color1;
//       padding: 55px 0 60px;
//       @include bpPcs {
//         padding: 60px 0 0;
//       }
//       @include bpSp {
//         padding: 45px 0 0;
//       }
//     }
//     .footer-nav {
//       display: flex;
//       justify-content: flex-start;
//       max-width: 1000px;
//       margin-left: auto;
//       margin-right: auto;
//       padding-left: 25px;
//       @include bpPcs {
//         flex-wrap: wrap;
//         padding-left: 0;
//       }
//       .footer-nav-item {
//         width: 16%;
//         padding-right: 20px;
//         color: #fff;
//         line-height: 2.1;
//         @include bpPcs {
//           width: 100%;
//           padding-right: 0;
//         }
//         &:first-child {
//           @include f-size(13);
//           line-height: 1.7;
//           @include bpPcs {
//             padding-bottom: 20px;
//             border-bottom: 1px solid #85b79d;
//             @include f-size(18);
//             text-align: center;
//           }
//           @include bpSp {
//             @include f-size(14);
//           }
//         }
//         &:nth-child(2) {
//           width: 20%;
//           @include bpPcs {
//             width: 100%;
//           }
//         }
//         &:nth-child(3) {
//           width: 29%;
//           @include bpPcs {
//             width: 100%;
//           }
//         }
//         &:last-child {
//           padding-right: 0;
//         }
//         // &:not(:nth-child(n+3)) {
//         //   padding-top: 0;
//         // }
//         .title1 {
//           display: block;
//           @include f-size(18);
//           @include bpPcs {
//             display: inline-block;
//             @include f-size(24);
//           }
//         }
//         .title2 {
//           display: block;
//           padding-top: 8px;
//           font-family: $wFont1;
//           @include f-size(12);
//           @include bpPcs {
//             padding-top: 0;
//             @include f-size(15);
//           }
//         }
//         a,
//         .requirement {
//           display: block;
//           color: #fff;
//           @include f-size(13);
//           &:hover {
//             opacity: 0.95;
//             text-decoration: none;
//             -webkit-font-smoothing: antialiased;
//           }
//           @include bpPcs {
//             padding: 20px 3%;
//             border-bottom: 1px solid #85b79d;
//             @include f-size(16);
//           }
//           @include bpSp {
//             padding: 15px 5%;
//             @include f-size(14);
//           }
//         }
//         .footer-nav-more {
//           @include bpPcs {
//             display: none;
//           }
//           li {
//             @include bpPcs {
//               background-color: #fff;
//             }
//             a {
//               padding-left: 0.8em;
//               @include f-size(12);
//               @include bpPcs {
//                 padding-left: 2em;
//                 color: $textColor;
//                 border-color: #cfcfcf;
//                 @include f-size(16);
//               }
//               @include bpSp {
//                 @include f-size(14);
//               }
//             }
//           }
//         }
//       }
  
//       .requirement {
//         position: relative;
//         @include bpPcs {
//         &::after {
//           font-family: "FontAwesome";
//           content: "\f107";
//           font-weight: 900;
//           position: absolute;
//           top: 21%;
//           right: 0;
//           width: 30px;
//           height: 30px;
//           @include f-size(18);
//         }
//         &.open {
//           &::after {
//             font-family: "FontAwesome";
//             content: "\f106";
//             position: absolute;
//             top: 21%;
//             right: 0;
//             width: 30px;
//             height: 30px;
//           }
//         }
//       }
//     }
//     }
//     .footer-row2 {
//       padding-top: 20px;
//       padding-bottom: 20px;
//       @include bpPcs {
//         padding-bottom: 30px;
//       }
//       .copyright {
//         display: block;
//         padding-right: 20px;
//         font-family: $wFont1;
//         @include f-size(11);
//         text-align: right;
//         line-height: 1;
//         @include bpPcs {
//           padding-right: 0;
//           text-align: center;
//           line-height: 1.6;
//         }
//       }
//     }
//   }
  
//   // メイン設定
//   .l-main {
//     padding-left: 200px;
//     @include bpPcs {
//       padding-left: 0;
//     }
//   }
  
//   .l-content {
//     @include wrapper;
//     &.notfound {
//       padding: 80px 0;
//       .m-title3 {
//         margin-bottom: 20px;
//       }
//     }
//   }
  
//   // メインビジュアル（TOP以外）
//   .l-mainvisual {
//     padding-bottom: 32.25%;
//     @include bpSp {
//       padding-bottom: 50%;
//     }
//     &.concept {
//       background: url(../img/concept/mainimg.jpg) no-repeat center top;
//       background-size: cover;
//     }
//     &.painting {
//       background: url(../img/painting/mainimg.jpg) no-repeat center top;
//       background-size: cover;
//     }
//     &.movie {
//       background: url(../img/movie/mainimg.jpg) no-repeat center top;
//       background-size: cover;
//     }
//     &.aid {
//       background: url(../img/aid/mainimg.jpg) no-repeat center top;
//       background-size: cover;
//     }
//     &.about {
//       background: url(../img/about/mianimg.jpg) no-repeat center top;
//       background-size: cover;
//     }
//   }
  
//   // ぱんくずリスト
//   .l-breadCrumbs {
//     width: 100%;
//     padding: 13px 13px 0 13px;
//     @include f-size(12);
//     letter-spacing: 0.05em;
//     @include bpSp {
//       @include f-size(10);
//     }
//     a {
//       @include f-size(12);
//     }
//     span {
//       @include f-size(12);
//     }
//     span[typeof="ListItem"] {
//       @include f-size(12);
//       a {
//         @include f-size(12);
//       }
//     }
//   }
// }